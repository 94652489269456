import { CoreActivityPageProps } from "@/src/page-data/classes";
import { Box, Divider } from "@outschool/backpack";
import { ClassDetailsNoSectionsQuery } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { searchPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  CdpMobileListingCarouselComponent,
  MobileActivityListComponent,
  MobileTopicSection,
  RecentlyViewListingCarousel,
  TopicInfo,
} from "@outschool/ui-components-website";
import {
  CurvedGradientSection,
  Space,
} from "@outschool/ui-legacy-component-library";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { useMemo } from "react";

const MAX_RELATED_CATEGORIES_NUM = 5;
const CAROUSEL_ROW_NUM = 0;

type CoreMobileBelowClassDetailProps = CoreActivityPageProps & {
  otherListingsRef: React.MutableRefObject<null>;
  activity: ClassDetailsNoSectionsQuery["activity"];
  leader: NonNullable<ClassDetailsNoSectionsQuery["activity"]["leader"]>;
};
export const CoreMobileBelowClassDetail = ({
  otherListingsRef,
  activity,
  leader,
  ...props
}: CoreMobileBelowClassDetailProps) => {
  const { t } = useTranslation(
    "ssr-client\\components\\detailPage\\CoreMobileNotEnrolledView"
  );
  const Link = useLinkComponent();
  const pageLocation = "ClassDetail_SSR_Mobile";
  const { listings, activityListings } = props;

  const relatedCategoriesTopics: TopicInfo[] = useMemo(() => {
    return activity.relatedCategories?.map(
      ({ nameTitleCased, basePath, slug }) => ({
        title: nameTitleCased,
        basePath,
        slug,
      })
    );
  }, [activity.relatedCategories]);

  return (
    <CurvedGradientSection>
      <Box
        ref={otherListingsRef}
        sx={{
          minHeight: "500px",
          padding: "3rem 0",
        }}
      >
        {!!activityListings.upsellListing && (
          <MobileActivityListComponent
            lists={[activityListings.upsellListing]}
            pageLocation={pageLocation}
            title={t("{{teacherName}} suggests this class next...", {
              teacherName: leader.name,
            })}
          />
        )}

        {!!listings.moreFromTeacher?.length && (
          <>
            <Space y={14} />
            <CdpMobileListingCarouselComponent
              listings={listings.moreFromTeacher}
              carouselRowNum={CAROUSEL_ROW_NUM}
              title={t("Other classes by {{teacherName}}", {
                teacherName: leader.name,
              })}
            />
            <Divider sx={{ borderColor: "gray100", marginTop: "2.4rem" }} />
          </>
        )}

        <RecentlyViewListingCarousel
          carouselRowNum={CAROUSEL_ROW_NUM}
          activity={activity}
        />

        {!!relatedCategoriesTopics?.length && (
          <>
            <MobileTopicSection
              title={t("Related categories")}
              topics={relatedCategoriesTopics}
              maxTopicsNum={MAX_RELATED_CATEGORIES_NUM}
            />
            <Divider sx={{ borderColor: "gray100", marginTop: "2.4rem" }} />
          </>
        )}

        {!!activityListings.parentsAlsoLiked?.length && (
          <>
            <MobileActivityListComponent
              lists={activityListings.parentsAlsoLiked}
              pageLocation={pageLocation}
              title={t("Parents also liked")}
            />
            <TrackedButton
              variant="outlined"
              sx={{
                width: "min-content",
                display: "flex",
                margin: "4rem auto",
              }}
              component={Link}
              to={searchPath({ q: activity.title, offset: 16 })}
              trackingName="mobile_below_class_detail_see_more_classes"
              trackingSharedProperties={{
                activity_uid: activity.uid,
                cards_loaded: 16,
              }}
            >
              {t("See More Classes")}
            </TrackedButton>
          </>
        )}

        {!!props.randomPopularCategories?.length && (
          <MobileTopicSection
            title={t("More to explore")}
            topics={props.randomPopularCategories}
          />
        )}
      </Box>
    </CurvedGradientSection>
  );
};
