import { Box, Typography } from "@outschool/backpack";
import { addParamsToUrl, removeParamsFromUrl } from "@outschool/routes";
import * as Time from "@outschool/time";
import { useTrackEvent } from "@outschool/ui-analytics";
import {
  AuthTrigger,
  persistPostLoginPath,
  withSession,
} from "@outschool/ui-auth";
import { ErrorMessage, ExternalLink } from "@outschool/ui-components-shared";
import {
  SendMessageToOutschoolMutation,
  useTopNoticeContext,
} from "@outschool/ui-components-website";
import {
  ModalActionButtons,
  TextArea,
} from "@outschool/ui-legacy-component-library";
import { Screen } from "@outschool/ui-utils";
import React from "react";

import * as Env from "../../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import * as User from "../../shared/User";
import PageModal from "./PageModal";

/**
 * Generic button that lets the user send a message to Outschool.
 */
@withSession
class MessageModal extends React.Component {
  static displayName = "MessageModal";

  static defaultProps = {
    user: {},
    viewName: "sendMessage",
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState(props);
    this.messageArea = React.createRef();
  }

  defaultState(props) {
    const { location, viewName } = props;
    const open =
      location &&
      location.query &&
      viewName &&
      location.query.openMB === viewName;
    return {
      open,
      message: this.defaultMessage(props),
    };
  }

  defaultMessage(props) {
    return props.defaultText || "";
  }

  defaultEmail(senderEmail, user) {
    return senderEmail || (user && user.email) || "";
  }

  close = e => {
    const { history, location } = this.props;
    e && e.preventDefault();
    if (location && location.query && location.query.openMB) {
      history.replace(
        removeParamsFromUrl(location.pathname + location.search, ["openMB"])
      );
    }
    this.setState({
      open: false,
      message: this.defaultMessage(this.props),
    });
  };

  open = e => {
    const {
      requireLogin,
      isLoggedIn,
      history,
      location,
      trackEvent,
      viewName,
    } = this.props;
    e && e.preventDefault();
    if (location && requireLogin && !isLoggedIn) {
      persistPostLoginPath(
        addParamsToUrl(location.pathname + location.search, {
          openMB: viewName,
        })
      );
      history.push(
        {
          pathname: location.pathname,
          query: {
            ...location.query,
            authTrigger: `${AuthTrigger.MESSAGE_TEACHER}`,
            signup: true,
          },
        },
        { noScroll: true }
      );
      return;
    }
    trackEvent("MessageButton clicked", this.props.context);
    this.setState({ open: true });
  };

  sendToOutschool = async () => {
    const { activityUid, sectionUid, context, setTopNotice, client } =
      this.props;
    const { message } = this.state;
    const content = message.trim();
    if (content) {
      try {
        const {
          data: { sendMessageToOutschool: success },
        } = await client.mutate({
          mutation: SendMessageToOutschoolMutation,
          variables: {
            messageInput: {
              content: message,
              activityUid,
              sectionUid,
              details: context || {},
            },
          },
        });
        if (success) {
          this.close(); // Close before setting top notice
          setTopNotice("We received your message. Thanks!");
        } else {
          this.setState({
            errorMessage:
              "Failed to send message. Please reload this page and try again.",
          });
        }
      } catch (e) {
        this.setState({ errorMessage: e });
      }
    } else {
      this.setState({ errorMessage: "Please write a message." });
    }
  };

  componentWillUnmount() {
    this.messageArea.current && this.messageArea.current.blur();
  }

  render() {
    const {
      renderButton,
      hideEmail,
      heading,
      prompt,
      subject,
      policyLink,
      policy,
      placeholder,
      viewName,
      recipients,
      toOutschool,
      senderEmail,
      user,
      showFromAndTo,
    } = this.props;

    const { open, message } = this.state;

    const email = this.defaultEmail(senderEmail, user);

    let fromAndTo;
    if (showFromAndTo && !toOutschool) {
      const showLocalTime =
        recipients.length === 1 &&
        !!recipients[0].timeZone &&
        recipients[0].timeZone !== User.timeZone(user);
      const now = new Date();
      fromAndTo = (
        <Box
          sx={{
            marginTop: "0.5em",
            color: "grey.500",
          }}
        >
          <div>
            <Typography
              variant="inherit"
              sx={{
                fontWeight: 500,
              }}
            >
              From:
            </Typography>{" "}
            you&nbsp;&nbsp;
            {showLocalTime && (
              <Typography
                variant="inherit"
                sx={{
                  color: "grey.500",
                }}
              >
                (local time: {Time.formatTime(now, User.timeZone(user), false)})
              </Typography>
            )}
          </div>
          <div>
            <Typography
              variant="inherit"
              sx={{
                fontWeight: 500,
              }}
            >
              To:
            </Typography>{" "}
            {recipients.map((recipient, index) => (
              <React.Fragment key={recipient.uid}>
                {recipient.name}
                {showLocalTime && (
                  <Typography
                    variant="inherit"
                    sx={{
                      color: "grey.500",
                    }}
                  >
                    &nbsp;&nbsp; (local time:{" "}
                    {Time.formatTime(now, recipient.timeZone, false)})
                  </Typography>
                )}
                {index < recipients.length - 1 ? <span>, &nbsp;</span> : null}
              </React.Fragment>
            ))}
          </div>
        </Box>
      );
    }

    return (
      <React.Fragment>
        {renderButton(this.open)}
        <PageModal open={open} onClose={this.close} viewName={viewName}>
          <ErrorMessage
            value={this.state.errorMessage}
            showStatusPageLink={Env.IS_READ_ONLY_MODE}
          />
          <form
            method="POST"
            onSubmit={e => {
              e.preventDefault();
              if (toOutschool) {
                this.sendToOutschool();
              }
            }}
          >
            <div>
              {heading && (
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "1em",
                    marginRight: "1em",
                  }}
                >
                  {heading}
                </Typography>
              )}
              {prompt && (
                <div>
                  {typeof prompt === "function" ? prompt(this.close) : prompt}
                </div>
              )}
              <ExternalLink url={policyLink}>{policy}</ExternalLink>
              {fromAndTo}
              {subject && (
                <Box
                  sx={{
                    color: "grey.500",
                  }}
                >
                  <Typography
                    variant="inherit"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Subject:
                  </Typography>{" "}
                  {subject}
                  &nbsp;&nbsp;
                </Box>
              )}
              <TextArea
                placeholder={placeholder || "Write your message here"}
                ref={this.messageArea}
                showCount={false}
                rows={4}
                maxRows={7}
                value={message}
                onChange={e =>
                  this.setState({
                    message: e.target.value,
                  })
                }
              />
              {!hideEmail && <div>Responses will go to {email}</div>}
            </div>
            <ModalActionButtons
              cancelLabel="Don't Send"
              onCancel={this.close}
              primaryLabel="Send"
              primaryType="submit"
            />
          </form>
        </PageModal>
      </React.Fragment>
    );
  }
}

function MessageModalWrapped(props) {
  const isMobile = Screen.useIsMobile();
  const trackEvent = useTrackEvent();
  const { setTopNotice } = useTopNoticeContext();
  return (
    <MessageModal
      {...props}
      trackEvent={trackEvent}
      isMobile={isMobile}
      setTopNotice={setTopNotice}
    />
  );
}

export default MessageModalWrapped;
