import { AnyActivityPageProps, PageExperience } from "@/src/page-data/classes";
import { UrlParamKeys } from "@outschool/data-schemas";
import { ClassPageVisitHistory } from "@outschool/ui-components-website";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useRouter } from "next/router";
import React from "react";

import CoreNotEnrolledView from "./CoreNotEnrolledView";
import FirstLandingNotEnrolledView from "./FirstLandingNotEnrolledView";

export default function ActivityDetailPage({
  activity,
  ...props
}: AnyActivityPageProps) {
  const { query } = useRouter();
  ClassPageVisitHistory.pushToTop(activity.uid);

  if (!activity.canEnroll && query[UrlParamKeys.OsFleExp] !== "core") {
    return (
      <FirstLandingNotEnrolledView
        activity={activity}
        leader={activity.leader}
        showDetails
        exploreMoreCategories={[]}
        listings={{
          searchListings: [],
        }}
        {...props}
        pageExperience={PageExperience.FirstLanding}
      />
    );
  }

  return props.pageExperience === PageExperience.Core ? (
    <CoreNotEnrolledView
      activity={activity}
      leader={activity.leader}
      showDetails
      {...props}
    />
  ) : (
    <FirstLandingNotEnrolledView
      activity={activity}
      leader={activity.leader}
      showDetails
      {...props}
    />
  );
}
