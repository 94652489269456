// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import {
  centsToDollars,
  perStudentPriceInCents,
} from "@outschool/business-rules";
import { imageUrl as getImageUrl } from "@outschool/filestack-urls";
import {
  ClassDetailsQuery_ActivityFragment,
  ClassDetailsQuery_SectionFragment,
} from "@outschool/gql-frontend-generated";
import * as Localization from "@outschool/localization";
import {
  SUPPORTED_LOCALES,
  renderCurrencyString,
  useLocale,
} from "@outschool/localization";
import {
  createUrlWithActivity,
  leaderPath,
  pathToUrl,
} from "@outschool/routes";
import Head from "next/head";
import React from "react";

import { MAIN_IMAGE_HEIGHT, MAIN_IMAGE_WIDTH } from "../../../shared/Activity";

type ActivityMetaTagsProps = {
  activity: ClassDetailsQuery_ActivityFragment;
  section?: ClassDetailsQuery_SectionFragment;
};

type CourseMetadataProps = {
  activity: Pick<
    ClassDetailsQuery_ActivityFragment,
    | "uid"
    | "title"
    | "summary"
    | "published_at"
    | "slug_id"
    | "isClub"
    | "details"
    | "leader"
    | "averageStarRating"
    | "reviewCount"
    | "price_cents"
    | "duration_weeks"
    | "weekly_meetings"
    | "is_ongoing_weekly"
    | "completedEnrollmentCount"
  >;
  imageUrl: string;
  section: Pick<ClassDetailsQuery_SectionFragment, "uid">;
};

function useCourseMetadata({
  activity,
  imageUrl,
  section,
}: CourseMetadataProps) {
  const locale = useLocale();

  return React.useMemo(() => {
    const price = renderCurrencyString({
      priceInCents: activity.price_cents ?? 0,
      showCurrencyCode: true,
      locale: locale,
    })
      .slice(1)
      .split(" ");
    const activityUrl = createUrlWithActivity(activity, section);

    const courseData = {
      "@context": "https://schema.org",
      "@type": "Course",
      name: activity.title,
      description: activity.summary,
      image: imageUrl,
      datePublished: activity.published_at,
      url: activityUrl,
      provider: {
        "@type": "Person",
        name: activity.leader ? activity.leader.name : "Outschool Teacher",
      },
      totalHistoricalEnrollment: activity?.completedEnrollmentCount ?? 0,
      offers: {
        "@type": "Offer",
        category: activity?.price_cents > 0 ? "Paid" : "Free",
        price: price[0],
        priceCurrency: activity?.price_cents > 0 ? price[1] : undefined,
      },
      hasCourseInstance: [
        {
          "@type": "CourseInstance",
          courseMode: "Online",
          courseWorkLoad: activity.is_ongoing_weekly
            ? "P" + (activity.duration_weeks || 1) + "W"
            : "P1H",
          courseSchedule: activity.is_ongoing_weekly
            ? {
                "@type": "Schedule",
                repeatCount: activity.weekly_meetings ?? undefined,
                repeatFrequency: activity.is_ongoing_weekly
                  ? "Weekly"
                  : undefined,
              }
            : undefined,
          instructor: [
            {
              "@type": "Person",
              name: activity.leader
                ? activity.leader.name
                : "Outschool Teacher",
              description: activity?.leader?.details?.headlineTranslated,
              url: pathToUrl(
                leaderPath(activity?.leader?.uid, activity?.leader?.leader_link)
              ),
            },
          ],
        },
      ],
    };

    let reviewData;
    let aggregateRating;
    const ratingValue =
      activity?.averageStarRating > 0
        ? parseFloat(activity?.averageStarRating.toFixed(2))
        : 0;
    const ratingCount = activity?.reviewCount ?? 0;

    if (ratingValue > 0 && ratingCount > 0) {
      aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: ratingValue.toString(),
        ratingCount: ratingCount.toString(),
        reviewCount: ratingCount.toString(),
      };

      reviewData = {
        "@context": "https://schema.org/",
        ...aggregateRating,
        bestRating: "5",
        worstRating: "1",
        itemReviewed: {
          ...courseData,
        },
      };
    }

    // if review data exists then return an array
    const metadataRollup = !!reviewData ? [reviewData, courseData] : courseData;
    return {
      type: "application/ld+json",
      innerHTML: JSON.stringify(metadataRollup, undefined, 2),
    };
  }, [activity, imageUrl, section, locale]);
}

export default function ActivityMetaTags({
  activity,
  section,
}: ActivityMetaTagsProps) {
  const title = activity.titleTranslated;

  // both dimensions must be > 200 for Facebook to accept as
  // link preview
  const previewImageWidth = 2 * MAIN_IMAGE_WIDTH;
  const previewImageHeight = 2 * MAIN_IMAGE_HEIGHT;

  const imageUrl = getImageUrl(activity.details.photo, {
    w: previewImageWidth,
    h: previewImageHeight,
    fit: "crop",
  });

  const priceInDollars = centsToDollars(
    perStudentPriceInCents(activity, section)
  );

  const courseMetadata = useCourseMetadata({ activity, imageUrl, section });
  const canonicalUrl = createUrlWithActivity(activity);

  // Sometimes summary translated is null
  const descriptionT = activity.summaryTranslated || activity.summary;
  let hrefLangUrls = [];
  for (let supportedLocale of SUPPORTED_LOCALES) {
    let langUrl = createUrlWithActivity(activity, null, null, supportedLocale);
    // @ts-ignore
    hrefLangUrls.push({
      rel: "alternate",
      hreflang: supportedLocale,
      href: langUrl,
    });
  }

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={descriptionT.slice(0, 155)} />
      <meta property="og:description" content={descriptionT} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:image:height"
        content={previewImageHeight.toString()}
      />
      <meta property="og:image:width" content={previewImageWidth.toString()} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="product" />
      <meta property="product:price:amount" content={`${priceInDollars}`} />
      <meta
        property="product:price:currency"
        content={Localization.BASE_CURRENCY.code}
      />
      <link rel="canonical" href={canonicalUrl} />
      {hrefLangUrls.map((link, index) => (
        <link
          key={index}
          rel={link.rel}
          hrefLang={link.hreflang}
          href={link.href}
        />
      ))}
      {courseMetadata && courseMetadata.type && courseMetadata.innerHTML ? (
        <script
          id="course-metadata-inline-script"
          type={courseMetadata.type}
          dangerouslySetInnerHTML={{ __html: courseMetadata.innerHTML }}
        />
      ) : null}
    </Head>
  );
}
