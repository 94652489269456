import { AnyActivityPageProps } from "@/src/page-data/classes";
import ActivityDetailPage from "@/src/website/client/components/detailPage/ActivityDetailPage";
import ActivityMetaTags from "@/src/website/client/components/sharing/ActivityMetaTags";
import { DeviceTypeContext } from "@outschool/ui-utils";
import React, { useEffect } from "react";

export function ActivityPage({ activity, ...props }: AnyActivityPageProps) {
  const deviceType = props.deviceType;
  const device = { deviceType };
  /*
   * This resolves a temporary navigation issue while the SSR experiment is
   * in flight. Remove this after classes and groups aren't potentially
   * served from two distinct apps under the same URL.
   */
  useEffect(() => {
    const originalPath = window.location.pathname;
    const onPopState = () => {
      const currentPath = window.location.pathname;
      if (originalPath === currentPath) {
        return;
      }
      window.location.reload();
    };

    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  return (
    <DeviceTypeContext.Provider value={device}>
      <ActivityMetaTags activity={activity} />
      <main>
        <ActivityDetailPage activity={activity} {...props} />
      </main>
    </DeviceTypeContext.Provider>
  );
}
