import * as Time from "@outschool/time";
import lodashDifference from "lodash/difference";
import lodashSortBy from "lodash/sortBy";
import lodashUniq from "lodash/uniq";

import * as Env from "../../Env";

export function shortenedLeaderName(leaderName) {
  if (!leaderName) {
    return "";
  }
  if (leaderName.length <= 30) {
    return leaderName;
  }
  return leaderName.slice(0, 30).concat("...");
}

export function senderName(user) {
  return (user && (user.name || user.email)) || "";
}

export function preferredTimeRanges(user) {
  const { details } = user || {};
  if (!details) {
    return [];
  }
  const { timeRangeSchool, timeRangeAfterSchool, timeRangeWeekend } = details;
  if (
    timeRangeSchool !== false &&
    timeRangeAfterSchool !== false &&
    timeRangeWeekend !== false
  ) {
    return [];
  }
  const times = ["School hours", "After school", "Weekend"];
  const timesNotWanted = [];
  if (timeRangeSchool === false) {
    timesNotWanted.push("School hours");
  }
  if (timeRangeAfterSchool === false) {
    timesNotWanted.push("After school");
  }
  if (timeRangeWeekend === false) {
    timesNotWanted.push("Weekend");
  }
  return lodashDifference(times, timesNotWanted);
}

function locationTimeZone(user) {
  return user && user.location && user.location.timeZone;
}

function browserTimeZone(user) {
  return Env.isBrowser
    ? Time.guessBrowserTimeZone()
    : user && user.details && user.details.browserTimeZone;
}

export function timeZone(user, defaultTimeZone = Time.OUTSCHOOL_TIMEZONE) {
  const locationZone = locationTimeZone(user);
  if (Time.zoneIsValid(locationZone)) {
    return locationZone;
  }
  const browserZone = browserTimeZone(user);
  if (Time.zoneIsValid(browserZone)) {
    return browserZone;
  }
  return defaultTimeZone;
}

export function preferredAges(user, learners = null) {
  if (Array.isArray(learners)) {
    return lodashSortBy(lodashUniq(learners.map(l => l.age)));
  }
  return user && user.children
    ? lodashSortBy(lodashUniq(user.children.map(c => c.age)))
    : [];
}

export function isSellerOrg(user) {
  return Boolean(
    user &&
      (user.owner_of_seller_org_uid ||
        user.ownerOfSellerOrg ||
        (user.sellerOrg && user.sellerOrg.currentUserIsOwner))
  );
}
