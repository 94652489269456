import { Roles } from "@outschool/auth-shared";
import { User } from "@outschool/gql-backend-generated";

export function isOrganizationAdmin(user: User): boolean {
  return Boolean(user?.organizationAdmin);
}

export function isOrganizationAdminWhoCanEnroll(
  user: User //This is not necessary currentUser
): boolean {
  return (
    user?.organizationAdmin &&
    user?.roles?.includes(Roles.CanOrganizationEnroll)
  );
}
