import { ContentfulBannerItem } from "@/src/lib/server-side-fetch/fetchContentfulBanners";
import { HomePageContent } from "@/src/lib/server-side-fetch/fetchContentfulHomePage2";
import { SitewideProps } from "@/src/lib/sitewideProps";
import { TopicInfo } from "@/src/website/shared/Activity";
import {
  ActivityListing_ListingFragment,
  ClassDetailsNoSectionsQuery,
  ClassDetailsQuery_ActivityFragment,
  ExploreMoreCategoryPagesQuery,
} from "@outschool/gql-frontend-generated";
import { DeviceType } from "@outschool/ui-utils";

import type { ListingCard_ListingFragment } from "@outschool/gql-frontend-generated";

export enum PageExperience {
  /**
   *
   * Pre-existing experience for CDP shown also showed to logged in customers
   */
  Core,
  /**
   *
   * First landing experience for CDP that shows to first time customers
   */
  FirstLanding,
}

type SharedActivityProps = {
  activity: ClassDetailsQuery_ActivityFragment;
  deviceType: DeviceType;
  activityListings?: {
    parentsAlsoLiked: ActivityListing_ListingFragment[];
    upsellListing: ActivityListing_ListingFragment | null;
  };

  /**
   * To initialize the experiment provider when the server renders the page
   */
  experimentName?: string;
  experimentVariant?: string;

  /**
   * Used for the SEO banner when the class is not enrollable.
   * If winnered, this prop can then be part of a new type
   *
   */
  similarListings: ListingCard_ListingFragment[];
};

export type CoreActivityProps = {
  pageExperience: PageExperience.Core;
  isUnderExperimentation: false;
  randomPopularCategories?: TopicInfo[];

  listings: {
    searchListings: ListingCard_ListingFragment[];
    moreFromTeacher: ListingCard_ListingFragment[];
  };
} & SharedActivityProps;

export type FirstLandingActivityProps = {
  pageExperience: PageExperience.FirstLanding;
  isUnderExperimentation: false;
  exploreMoreCategories: ExploreMoreCategoryPagesQuery["topLevelCategoryPages"];

  // This being a frontend experiment.
  // We could manage this to be fully SSR.
  // Experiment on first-landing mobile page
  visualLinkGrids?: HomePageContent["visualLinkGrids"];
  contentfulBanners?: ContentfulBannerItem;
  listings?: {
    searchListings: ListingCard_ListingFragment[];
    exploreClasses?: ListingCard_ListingFragment[];
  };
} & SharedActivityProps;

/**
 *
 * Extending types to handle experiments.
 *
 * In case that you need a new type for a server-run experiment follow this
 * guide:
 *
 * 1. Duplicate the type for the experience to which your experiment will apply
 * 2. set `isUnderExperimentation` to true
 * 3. set an `experimentName`, `experimentVariant` & `deviceType` if your
 *    experiment will apply to only one platform.
 * 4. set any extra data properties you'll require for your experiment, and
 *    remove any data you won't require
 * 5. Change the name and place your type as a union of the original main type
 *
 * Example:
 *
 * I'm running the experiment  "PopularCategories-Mobile" with variants control
 * and treatment and will apply for first landing experience. This is the final
 * form of the types:
 *
 * export type PopularCategoriesTreatmentFirstLandingActivityProps = {
 *   pageExperience: PageExperience.FirstLanding;
 *   isUnderExperimentation: true;
 *   experimentName: "PopularCategories-Mobile",
 *   experimentVariant: "treatment",
 *   deviceType: DeviceType.Mobile,
 *   popularCategires?: SomeCateogriesType[];
 * } & SharedActivityProps;
 *
 * export type FirstLandingActivityPageProps =
 *  FirstLandingActivityProps |
 *  PopularCategoriesTreatmentFirstLandingActivityProps & SitewideProps
 *
 *
 */

export type CoreActivityPageProps = CoreActivityProps & SitewideProps;
export type FirstLandingActivityPageProps = FirstLandingActivityProps &
  SitewideProps;

export type AnyActivityPageProps =
  | (CoreActivityPageProps & Pick<SitewideProps, "experimentAssignments">)
  | (FirstLandingActivityPageProps &
      Pick<SitewideProps, "experimentAssignments">);

export type ClassDetailsActivityType = ClassDetailsNoSectionsQuery["activity"];
