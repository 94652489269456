import { Box } from "@outschool/backpack";
import { Search } from "@outschool/business-rules";
import {
  ScheduleSuggestionsQuery,
  ScheduleSuggestionsQueryVariables,
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession,
} from "@outschool/ui-auth";
import { LazyBox, TrackedButton } from "@outschool/ui-components-shared";
import {
  ChooseATimeOrClassScheduleRequestButtonProps,
  ScheduleSuggestionsQuery as ScheduleSuggestionsGqlQuery,
} from "@outschool/ui-components-website";
import { useRouter } from "next/router";
import React, { useCallback, useMemo } from "react";

import ClassScheduleRequest from "../ClassScheduleRequest";

export default function ChooseATimeOrClassScheduleRequestButton({
  activity,
  leader,
  variant = "outlined",
  buttonText,
  sx,
  locationRenderedOn,
}: ChooseATimeOrClassScheduleRequestButtonProps) {
  const { t } = useTranslation(
    "ssr-client\\components\\detailPage\\ChooseATimeOrClassScheduleRequestButton"
  );
  const router = useRouter();
  const { currentUser, isLoggedIn, userTimeZone, isEsaSession } = useSession();

  const [startAfterMonthOption] = React.useMemo(
    () =>
      Search.getStartAfterDateOptions(
        activity.autoSchedulingDaysNoticeMin,
        activity.autoSchedulingDaysNoticeMax,
        userTimeZone
      ),
    [
      activity.autoSchedulingDaysNoticeMin,
      activity.autoSchedulingDaysNoticeMax,
      userTimeZone,
    ]
  );

  const { data, loading } = useQueryWithPreviousData<
    ScheduleSuggestionsQuery,
    ScheduleSuggestionsQueryVariables
  >(ScheduleSuggestionsGqlQuery, {
    variables: {
      uid: activity.uid,
      startAfter: startAfterMonthOption.startAfter.toDate(),
      startAfterTime: Time.AUTO_SCHEDULE_DAY_START_HOUR,
      endByTime: Time.AUTO_SCHEDULE_DAY_END_HOUR,
      timeZone: userTimeZone,
    },
  });

  const classScheduleRequestButton = useMemo(
    () => (
      <ClassScheduleRequest
        renderButton={open => (
          <TrackedButton
            variant={variant}
            onClick={open}
            trackingName="Request a time clicked"
            trackingUniqueId={activity.uid}
            trackingSharedProperties={{ locationRenderedOn }}
            sx={sx}
            color="neutral"
          >
            {buttonText || t("Request Another Time")}
          </TrackedButton>
        )}
        isLoggedIn={isLoggedIn}
        user={currentUser}
        userTimeZone={userTimeZone}
        activity={activity}
      />
    ),
    [
      variant,
      sx,
      buttonText,
      activity,
      isLoggedIn,
      t,
      userTimeZone,
      currentUser,
      locationRenderedOn,
    ]
  );

  const { enterLoginFlow } = useLoginFlowContext();
  const onAlternativeTimesClick = useCallback(() => {
    enterLoginFlow({
      authTrigger: AuthTrigger.REQUEST_TIME,
      skipLearners: true,
      newUrl: router.asPath,
    });
  }, [enterLoginFlow, router.asPath]);

  if (
    (router.query && router.query.openCSR) ||
    !!activity.isFlexSchedule ||
    !leader.hasAvailabilitySet ||
    !activity.isAutoSchedulingEnabled
  ) {
    return classScheduleRequestButton;
  }

  if (loading) {
    return null;
  }

  if (data?.activity?.scheduleSuggestions?.length > 0 && !isEsaSession) {
    return (
      <TrackedButton
        variant={variant}
        onClick={onAlternativeTimesClick}
        trackingName="show_alternative_times"
        trackingUniqueId={activity?.uid}
        trackingSharedProperties={{ locationRenderedOn }}
        sx={sx}
      >
        {buttonText || t("Show Alternative Times")}
      </TrackedButton>
    );
  }

  return (
    <LazyBox placeholder={<Box />} rootMargin="150px 0px">
      {classScheduleRequestButton}
    </LazyBox>
  );
}
