import {
  Activity as ActivityType,
  Meeting,
  SearchFilters,
  Section,
} from "@outschool/gql-backend-generated";
import { plural } from "@outschool/text";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";
import { doesSectionHaveProperty } from "@outschool/ui-components-website";
import lodashIncludes from "lodash/includes";

type SectionTime = Pick<Section, "start_time" | "end_time">;

type SectionStatus = SectionTime &
  Pick<Section, "deleted_at" | "canceled_at" | "published_at"> &
  Partial<Pick<Section, "ongoing_stopped_at">>;

type OptionalFilledSpaceCount = Partial<Pick<Section, "filledSpaceCount">>;
type SectionOfferedSpaces = Pick<Section, "size_max">;
type SectionMaxSize = SectionOfferedSpaces & OptionalFilledSpaceCount;
type SectionMinSize = Pick<Section, "size_min"> & OptionalFilledSpaceCount;
type SectionSize = SectionMinSize & SectionMaxSize;

type ActivityFrequency = Pick<
  ActivityType,
  "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly" | "isFlexSchedule"
>;

type ActivityDuration = Pick<
  ActivityType,
  "duration_weeks" | "duration_minutes"
> &
  ActivityFrequency;

export type ActivityWaitlistable = Pick<ActivityType, "allowWaitlist">;

type MeetingTime = Pick<Meeting, "start_time" | "end_time">;

export type SectionSearchFilters = Pick<
  SearchFilters,
  | "dow"
  | "endBy"
  | "endByTime"
  | "startAfter"
  | "startAfterTime"
  | "startBefore"
>;

enum EDITING_STATUS {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  CANCELED = "canceled",
  DELETED = "deleted",
}

enum TIMING_STATUS {
  PAST = "past", // TODO(cp): Rename to COMPLETE?
  CURRENT = "current", // TODO(cp): Rename to IN_PROGRESS?
  FUTURE = "future",
}

const editingStatus: (
  section: SectionStatus
) => null | EDITING_STATUS = section => {
  if (!section) {
    return null;
  }

  if (section.deleted_at) {
    return EDITING_STATUS.DELETED;
  } else if (section.canceled_at) {
    return EDITING_STATUS.CANCELED;
  } else if (section.start_time && section.end_time) {
    return EDITING_STATUS.SCHEDULED;
  } else {
    return EDITING_STATUS.DRAFT;
  }
};

const timingStatus: (
  section: SectionTime
) => TIMING_STATUS | null = section => {
  const now = new Date();
  if (!!(section && section.end_time && new Date(section.end_time) < now)) {
    return TIMING_STATUS.PAST;
  } else if (
    section &&
    section.start_time &&
    new Date(section.start_time) < now &&
    (!section.end_time || new Date(section.end_time) > now) // no section end_time means ongoing || has not ended
  ) {
    return TIMING_STATUS.CURRENT;
  } else if (
    !!(section && section.start_time && new Date(section.start_time) > now)
  ) {
    return TIMING_STATUS.FUTURE;
  }
  return null;
};

export const isScheduled: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.SCHEDULED;
export const isCanceled: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.CANCELED;

export const isPast: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.PAST;
export const isComplete: doesSectionHaveProperty<SectionTime> = isPast;
export const isInProgress: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.CURRENT;
export const isFuture: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.FUTURE;

/**
 * @deprecated use `@outschool/section` instead
 */
export const offeredSpaces: (
  section: SectionOfferedSpaces
) => number = section => {
  return section.size_max ?? 0;
};

/**
 * @deprecated use `@outschool/section` instead
 */
export const availableSpaces = (
  section: SectionMaxSize,
  filledSpaceCount: number = 0
) => {
  const available =
    offeredSpaces(section) -
    (filledSpaceCount || section.filledSpaceCount || 0);
  return Math.max(0, available);
};

export const isPromotable: (
  activity: ActivityDuration,
  section: SectionStatus & SectionMaxSize,
  filledSpaceCount?: number
) => boolean = (activity, section, filledSpaceCount?) => {
  return (
    isScheduled(section) &&
    !isFull(section, filledSpaceCount) &&
    isPublished(section) &&
    (isFuture(section) ||
      (!!activity.is_ongoing_weekly && isInProgress(section)))
  );
};

export const isFull = (section: SectionMaxSize, filledSpaceCount?: number) =>
  !!(section && availableSpaces(section, filledSpaceCount) <= 0);

export const isWaitlistable = (
  activity: ActivityWaitlistable,
  section: SectionMaxSize,
  filledSpaceCount?: number
) => {
  return !!(isFull(section, filledSpaceCount) && activity.allowWaitlist);
};

export const isNotAcceptingWaitlistSeats = (
  activity: ActivityWaitlistable & Pick<ActivityType, "isClub">,
  section: SectionMaxSize,
  filledSpaceCount?: number
) => {
  return !!(
    isFull(section, filledSpaceCount) &&
    !activity.allowWaitlist &&
    !activity.isClub
  );
};

export const isPublished: doesSectionHaveProperty<
  Pick<Section, "published_at"> | Pick<Section, "isPublished">
> = section =>
  ("isPublished" in section && section.isPublished) ||
  ("published_at" in section && !!section.published_at);

export const matchesTimePreferences: (
  activity: ActivityDuration,
  section: SectionTime,
  preferredTimeRanges: string[],
  timeZone: string,
  nextOngoingMeeting?: MeetingTime
) => boolean = (
  activity,
  section,
  preferredTimeRanges,
  timeZone,
  nextOngoingMeeting
) => {
  if (activity.isFlexSchedule) {
    return true;
  }
  if (!preferredTimeRanges || (!preferredTimeRanges.length && !timeZone)) {
    return true;
  }
  preferredTimeRanges = preferredTimeRanges || [];
  let startTime = nextOngoingMeeting?.start_time
    ? dayjs(nextOngoingMeeting.start_time)
    : dayjs(section.start_time);
  let day = startTime.day();
  let hour = startTime.hour();
  if (timeZone && startTime.isValid()) {
    startTime = startTime.tz(timeZone);
    day = startTime.day();
    hour = startTime.hour();
    if (hour < Time.DAY_START_HOUR) {
      return false;
    }
    if (hour >= Time.DAY_END_HOUR) {
      return false;
    }
  }
  if (!preferredTimeRanges.length) {
    return true;
  }
  const isSummerSection = isSummer(section);
  return preferredTimeRanges.some(time => {
    switch (time) {
      case "Monday":
        return day === 1;
      case "Tuesday":
        return day === 2;
      case "Wednesday":
        return day === 3;
      case "Thursday":
        return day === 4;
      case "Friday":
        return day === 5;
      case "Saturday":
        return day === 6;
      case "Sunday":
        return day === 0;
      case "Weekend":
        return isSummerSection || day === 6 || day === 0;
      case "School hours":
        return isSummerSection || (day !== 6 && day !== 0);
      case "After school":
        return (
          !timeZone ||
          isSummerSection ||
          (lodashIncludes([1, 2, 3, 4, 5], day) &&
            hour >= Time.AFTER_SCHOOL_START_HOUR)
        );
      default:
        return true;
    }
  });
};

export const remainingSpotsMessage: (
  section: SectionSize,
  filledSpaceCount: number,
  minSize?: number
) => string = (section, filledSpaceCount, minSize) => {
  if (!section) {
    return "";
  }
  const remaining = availableSpaces(section, filledSpaceCount);

  // 1:1 classes should have no message
  if (offeredSpaces(section) === 1 && remaining === 1) {
    return "";
  }

  if (remaining > 3) {
    if (filledSpaceCount >= (minSize || section?.size_min || 0)) {
      return `${filledSpaceCount} ${plural(
        "learner",
        filledSpaceCount
      )} enrolled`;
    } else {
      return "";
    }
  } else if (remaining <= 0) {
    return "This section is sold out.";
  } else if (remaining === 1) {
    return "Only one spot remaining";
  } else {
    return `Only ${remaining} spots remaining`;
  }
};

export const statusMessage: (
  activity: ActivityDuration,
  section: SectionStatus & SectionSize,
  filledSpaceCount: number
) => string = (activity, section, filledSpaceCount) => {
  if (isComplete(section)) {
    return "This section has ended.";
  } else if (isInProgress(section) && !activity.is_ongoing_weekly) {
    return "This section has already started.";
  } else if (isCanceled(section)) {
    return "This section has been canceled.";
  } else if (isFull(section, filledSpaceCount)) {
    return "This section is full.";
  } else if (isPromotable(activity, section, filledSpaceCount)) {
    return remainingSpotsMessage(section, filledSpaceCount);
  } else {
    return "";
  }
};

export const isSummer: doesSectionHaveProperty<SectionTime> = section => {
  return !section.start_time || !section.end_time
    ? false
    : Time.isSummer(section.start_time) && Time.isSummer(section.end_time);
};
