// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { addParamsToUrl } from "@outschool/routes";
import { AuthTrigger, useLoginFlowContext } from "@outschool/ui-auth";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useRouter } from "next/router";
import React, { useCallback } from "react";

interface ClassScheduleRequestProps {
  activity: any;
  user: any;
  userTimeZone: string;
  isLoggedIn: boolean;
  renderButton?: (
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  ) => React.ReactElement;
}

function ClassScheduleRequest({
  activity,
  renderButton = open => (
    <TrackedButton
      onClick={open}
      trackingName="Request a time clicked"
      trackingUniqueId={activity?.uid}
    >
      Request Another Time
    </TrackedButton>
  ),
}: ClassScheduleRequestProps) {
  const router = useRouter();
  const open = false;

  const { enterLoginFlow } = useLoginFlowContext();
  const handleOpenClick = useCallback(() => {
    enterLoginFlow({
      authTrigger: AuthTrigger.REQUEST_TIME,
      skipLearners: true,
      newUrl: addParamsToUrl(router.asPath, {
        openCSR: true,
      }),
    });
  }, [enterLoginFlow, router.asPath]);

  if (!open) {
    return renderButton(handleOpenClick);
  }
}

export default ClassScheduleRequest;
