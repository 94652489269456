import { Box, Theme, Typography } from "@outschool/backpack";
import { SearchUseCase } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { searchPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  ActivityListing,
  CategoriesSearchComponent,
  OnlineClassesPageLink,
  TopicPill,
} from "@outschool/ui-components-website";
import {
  Container,
  Space,
  TrackingPageSection,
} from "@outschool/ui-legacy-component-library";
import { useIsMobile, useLinkComponent } from "@outschool/ui-utils";
import React from "react";

const CdpAsClpListings = (
  { activity, searchListings, exploreMoreCategories },
  ref: React.Ref<any>
) => {
  const { t } = useTranslation(
    "ssr-client\\components\\detailPage\\CdpAsClpListings"
  );
  const Link = useLinkComponent();
  const searchFilters = { q: activity.title };
  const isMobile = useIsMobile();

  return (
    <TrackingPageSection
      name="cdpAsClp"
      uniqueId="cdpAsClpImpression"
      uniqueProperties={{
        activity_uid: activity.uid,
      }}
      useLinkTracking
    >
      {setImpressionNode => (
        <Box
          ref={node => setImpressionNode(node)}
          sx={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            maxWidth: "1120px",
            justifyContent: "center",
          }}
        >
          <Container ref={ref}>
            {isMobile && <Space y={40} />}
            <Typography
              emphasized
              sx={(theme: Theme) => ({
                typography: "h5",
                [theme.breakpoints.up("md")]: {
                  typography: "h4",
                },
              })}
            >
              {t`Parents also liked`}
            </Typography>
          </Container>
          <Space y={16} />
          <Box
            sx={(theme: Theme) => ({
              marginX: "auto",
              maxWidth: "100%",
              [theme.breakpoints.up("md")]: {
                marginX: "-1em",
              },
            })}
          >
            {!!searchListings?.length
              ? searchListings.map((listing, activity_index) => {
                  return listing.activity.uid == activity.uid ? null : (
                    <React.Fragment key={listing.activity.uid}>
                      <ActivityListing
                        listing={listing}
                        openInNewTab
                        openInSSRForLoggedOutUsers={true}
                        trackingProperties={{
                          // searchUid,
                          order: activity_index,
                          activity_title: listing.activity.title,
                        }}
                        listingIndex={activity_index}
                        pageLocation={SearchUseCase.CdpAsClp}
                        variant={"cdpAsClp"}
                      />
                    </React.Fragment>
                  );
                })
              : null}
          </Box>
          <Container>
            <Space y={24} />
            <TrackedButton
              variant="outlined"
              sx={{ width: "min-content", margin: "auto", display: "flex" }}
              component={Link}
              to={searchPath({ ...searchFilters, offset: 16 })}
              trackingName="cdp_as_clp_load_more"
              trackingSharedProperties={{
                activity_uid: activity.uid,
                cards_loaded: 16,
              }}
            >
              {t("See More Classes")}
            </TrackedButton>
            <Space y={64} />
            {isMobile && !!activity.relatedCategories?.length ? (
              <>
                <Typography variant="h6">{t`Related categories`}</Typography>
                <Space y={18} />
                <Box
                  flex
                  sx={{
                    flexWrap: "wrap",
                    gap: 8,
                  }}
                >
                  {activity.relatedCategories.map(category => (
                    <OnlineClassesPageLink
                      pageSlug={category.slug}
                      pageBasePath={category.basePath}
                      key={category.slug}
                    >
                      <TopicPill>{category.nameTitleCased}</TopicPill>
                    </OnlineClassesPageLink>
                  ))}
                </Box>
                <Space y={59} />
              </>
            ) : null}
            {!isMobile && !!exploreMoreCategories?.length && (
              <>
                <Typography variant="h4">{t`Explore more`}</Typography>
                <Space y={24} />
                <Box
                  flex
                  sx={{
                    flexWrap: "wrap",
                    gap: "1.2rem 0.8rem",
                  }}
                >
                  {exploreMoreCategories.map(category => (
                    <OnlineClassesPageLink
                      pageSlug={category.slug}
                      pageBasePath={category.basePath}
                      key={category.uid}
                    >
                      <TopicPill>{category.nameTitleCased}</TopicPill>
                    </OnlineClassesPageLink>
                  ))}
                </Box>
              </>
            )}
          </Container>
          {isMobile && (
            <CategoriesSearchComponent heading={t("Find more classes")} />
          )}
          <Space y={isMobile ? 20 : 121} />
        </Box>
      )}
    </TrackingPageSection>
  );
};

export default React.forwardRef(CdpAsClpListings);
