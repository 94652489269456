import {
  FirstLandingActivityPageProps,
  PageExperience,
} from "@/src/page-data/classes";
import {
  ActivityEnrollableTimesModal_ActivityFragment,
  ClassDetailsQuery_LeaderFragment,
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import {
  addParamsToUrl,
  oneOnOneEnrollPath,
  searchPath,
  sectionEnrollPath,
  selfPacedEnrollPath,
} from "@outschool/routes";
import { useProductViewed } from "@outschool/ui-analytics";
import { useApolloClient } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession,
} from "@outschool/ui-auth";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  ActivityEnrollableTimesCta,
  ClassDetailsMainBody,
  TrustpilotFullBleedBanner,
  useCDPEnrollOrSeeScheduleCTA,
  useTrackClassPageImpression,
} from "@outschool/ui-components-website";
import { Space, TrackingPage } from "@outschool/ui-legacy-component-library";
import {
  useIsMobile,
  useIsMounted,
  useNavigation,
  useScrollToElement,
} from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { APP_HOSTNAME, FACEBOOK_APP_ID, OUTSCHOOL_ENV } from "../../../../Env";
import fetchOneOnOneEnrollableTimes from "../../../../lib/server-side-fetch/fetchOneOnOneEnrollableTimes";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { isOrganizationAdminWhoCanEnroll } from "../../lib/Users";
import SimpleLayout from "../layouts/SimpleLayout";
import MessageModal from "../MessageModal.jsx";
import CdpAsClpListings from "./CdpAsClpListings";
import ChooseATimeOrClassScheduleRequestButton from "./ChooseATimeOrClassScheduleRequestButton";
import { SectionCallToAction } from "./SectionCallToActionButtons";

type NotEnrolledViewProps = FirstLandingActivityPageProps & {
  leader: ClassDetailsQuery_LeaderFragment;
  showDetails: boolean;
};

export default function FirstLandingNotEnrolledView({
  leader,
  ...props
}: NotEnrolledViewProps) {
  const { activity } = props;
  const canEnroll = activity.canEnroll;
  const isMobile = useIsMobile();

  const router = useRouter();
  const client = useApolloClient();

  const { currentUser } = { currentUser: null };
  const isOrganizationEnrollment = isOrganizationAdminWhoCanEnroll(currentUser);

  const querySectionUid = router.query && router.query.sectionUid;
  const isPrepayMeetings =
    router.query?.isPrepayMeetings == "true"
      ? true
      : router.query?.isPrepayMeetings == "false"
      ? false
      : undefined;

  const sectionsRef = React.useRef(null);
  const otherListingsRef = React.useRef<HTMLElement>(null);
  const viewAvailabilityRef = React.useRef(null);
  const viewTheTeacherRef = React.useRef(null);

  const ctaText = useCDPEnrollOrSeeScheduleCTA({
    activity,
  });

  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] =
    React.useState(false);

  const scrollToElement = useScrollToElement();

  const { enterLoginFlow } = useLoginFlowContext();

  const trackProductViewed = useProductViewed();
  React.useEffect(() => {
    trackProductViewed({
      activity,
      leader,
    });
  }, [activity, leader, trackProductViewed]);

  const onClickSeeSchedule = React.useCallback(() => {
    if (activity.isSelfPaced) {
      enterLoginFlow({
        authTrigger: AuthTrigger.ENROLL,
        skipLearners: true,
        newUrl: selfPacedEnrollPath(activity),
      });
    } else {
      setIsAvailabilityModalOpen(true);
    }
  }, [enterLoginFlow, activity]);

  const navigate = useNavigation();
  const onClickMoreLikeThis = React.useCallback(() => {
    if (isMobile && isAvailabilityModalOpen) {
      navigate(searchPath({ q: activity.title }));
    }

    const stickySearchBarOffset = 66;
    const targetPos = otherListingsRef.current.getBoundingClientRect().top;
    const offsetPos = targetPos + window.pageYOffset - stickySearchBarOffset;

    window.scrollTo({
      top: isMobile ? offsetPos : targetPos,
      behavior: "smooth",
    });
  }, [isMobile, isAvailabilityModalOpen, navigate, activity.title]);

  const onClickViewAvailability = React.useCallback(() => {
    if (isMobile) {
      if (activity.isSelfPaced) {
        enterLoginFlow({
          authTrigger: AuthTrigger.ENROLL,
          skipLearners: true,
          newUrl: selfPacedEnrollPath(activity),
        });
      } else {
        setIsAvailabilityModalOpen(true);
      }
    } else if (viewAvailabilityRef.current) {
      scrollToElement(viewAvailabilityRef.current);
    }
  }, [scrollToElement, isMobile, activity, enterLoginFlow]);

  const onClickViewTeacher = React.useCallback(() => {
    scrollToElement(viewTheTeacherRef?.current);
  }, [scrollToElement]);

  React.useEffect(() => {
    if (querySectionUid) {
      let path = sectionEnrollPath(activity, querySectionUid);
      enterLoginFlow({
        authTrigger: AuthTrigger.ENROLL,
        skipLearners: true,
        newUrl: path,
      });
    }
  }, [querySectionUid, enterLoginFlow, activity]);

  // tracking
  const trackClassPageImpression = useTrackClassPageImpression();
  useEffect(() => {
    trackClassPageImpression({
      activityUid: activity.uid,
      sectionUid: querySectionUid as string,
    });
  }, [activity.uid, querySectionUid, trackClassPageImpression]);

  const { esaSessionData } = useSession();
  const { t } = useTranslation(
    "ssr-client\\components\\detailPage\\NotEnrolledView"
  );

  const [activityWithEnrollableTimes, setActivityWithEnrollableTimes] =
    React.useState<ActivityEnrollableTimesModal_ActivityFragment | undefined>(
      undefined
    );
  if (activity.hasTeacherSchedule) {
    fetchOneOnOneEnrollableTimes({
      client,
      uid: activity.uid,
      esaSessionData,
    }).then(a => setActivityWithEnrollableTimes(a));
  }
  const loadingOneOnOneActivity = !activityWithEnrollableTimes;
  const hasOneOnOneAvailabilities =
    !!activityWithEnrollableTimes?.enrollableTimes.length;

  const onEnrollClick = React.useCallback(
    ({
      startTime,
      orgTeacherUid,
    }: {
      startTime: Date;
      orgTeacherUid?: string;
    }) => {
      let path = oneOnOneEnrollPath({
        activity,
        startTime,
        orgTeacherUid,
        isPrepayMeetings,
      });
      enterLoginFlow({
        authTrigger: AuthTrigger.ENROLL,
        skipLearners: true,
        newUrl: path,
      });
    },
    [enterLoginFlow, activity, isPrepayMeetings]
  );

  const handleRequestAnotherTime = React.useCallback(() => {
    enterLoginFlow({
      authTrigger: AuthTrigger.REQUEST_TIME,
      skipLearners: true,
      newUrl: addParamsToUrl(router.asPath, {
        openCSR: "true",
      }),
    });
  }, [router.asPath, enterLoginFlow]);

  const SeeScheduleButtonMemo = React.useMemo(() => {
    if (activity.wasPublishedInThePast) {
      return (
        <TrackedButton
          variant="contained"
          sx={{ paddingX: "1em", flex: "1" }}
          onClick={onClickMoreLikeThis}
          trackingName="cdp_see_more_like_this"
        >
          {t`See More Like This`}
        </TrackedButton>
      );
    }

    if (
      activity.upcomingAvailableSectionCount === 0 &&
      activity.upcomingSectionCount === 0 &&
      !activity.isSelfPaced
    ) {
      return (
        <ChooseATimeOrClassScheduleRequestButton
          buttonText={t("Join Waitlist")}
          activity={activity}
          leader={leader}
          variant="contained"
          sx={{ width: "100%" }}
          locationRenderedOn="AvailableTimesTable"
        />
      );
    }

    if (activity.hasTeacherSchedule) {
      return (
        !loadingOneOnOneActivity && (
          <ActivityEnrollableTimesCta
            onRequestAnotherTimeClick={handleRequestAnotherTime}
            onClickViewAvailability={onClickViewAvailability}
            hasOneOnOneAvailabilities={hasOneOnOneAvailabilities}
            shouldHideAlert
          />
        )
      );
    } else {
      return (
        <TrackedButton
          trackingName={"see_schedule_mobile_nav"}
          trackingUniqueId={activity.uid}
          onClick={onClickSeeSchedule}
          variant="contained"
          sx={{ paddingX: "1em", flex: "1" }}
        >
          {ctaText}
        </TrackedButton>
      );
    }
  }, [
    activity,
    onClickSeeSchedule,
    t,
    handleRequestAnotherTime,
    onClickViewAvailability,
    loadingOneOnOneActivity,
    leader,
    hasOneOnOneAvailabilities,
    onClickMoreLikeThis,
    ctaText,
  ]);

  const showAvailableTimes = React.useMemo(() => {
    return !activity.isClub && !activity.hasTeacherSchedule;
  }, [activity]);

  // this is to prevent hydration errors from trustpilot loading dynamically
  const isMounted = useIsMounted();

  return (
    <TrackingPage
      uniqueId={activity.uid}
      activity={activity}
      name="Class Details Page"
      uniqueProperties={{
        landingExperience: PageExperience[props.pageExperience],
        experimentAssignments: props.experimentAssignments,
        enrollable: canEnroll,
      }}
      useLinkTracking
    >
      {setImpressionNode => (
        <SimpleLayout
          ref={setImpressionNode}
          allowGlobalNavbar
          footerCategories={props.footerCategories}
        >
          {isMounted && (
            <TrustpilotFullBleedBanner
              isSSR={true}
              env={OUTSCHOOL_ENV}
              appHostname={APP_HOSTNAME}
            />
          )}
          <ClassDetailsMainBody
            // data
            activity={activity}
            activityWithEnrollableTimes={activityWithEnrollableTimes}
            leader={leader}
            canEnroll={canEnroll}
            isCrossSellVariant={false}
            isOrganizationEnrollment={isOrganizationEnrollment}
            isLoadingOneOnOneAvailabilities={loadingOneOnOneActivity}
            hasOneOnOneAvailabilities={hasOneOnOneAvailabilities}
            facebookAppId={FACEBOOK_APP_ID}
            isFirstLanding
            showAvailableTimes={showAvailableTimes}
            // child components
            SectionCallToAction={SectionCallToAction}
            ChooseATimeOrClassScheduleRequestButton={
              ChooseATimeOrClassScheduleRequestButton
            }
            MessageModal={MessageModal}
            SeeScheduleButtonMemo={SeeScheduleButtonMemo}
            // scroll refs
            sectionsRef={sectionsRef}
            viewAvailabilityRef={viewAvailabilityRef}
            viewTheTeacherRef={viewTheTeacherRef}
            // event handlers
            onClickMoreLikeThis={onClickMoreLikeThis}
            onClickViewTeacher={onClickViewTeacher}
            onEnrollClick={onEnrollClick}
            isAvailabilityModalOpen={isAvailabilityModalOpen}
            setIsAvailabilityModalOpen={setIsAvailabilityModalOpen}
          />
          <CdpAsClpListings
            ref={otherListingsRef}
            activity={activity}
            searchListings={props.listings.searchListings}
            exploreMoreCategories={props.exploreMoreCategories}
          />
          <Space y={isMobile ? 20 : 121} />
        </SimpleLayout>
      )}
    </TrackingPage>
  );
}
