import { useTranslation } from "@outschool/localization";
import {
  sectionCharterSchoolEnrollPath,
  sectionEnrollPath,
  selfPacedEnrollPath,
} from "@outschool/routes";
import { AuthTrigger, useLoginFlowContext } from "@outschool/ui-auth";
import {
  TrackedButton,
  TrackedButtonProps,
} from "@outschool/ui-components-shared";
import { useEnrollButtonCTA } from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import * as Section from "../../../shared/Section";

export function SectionCallToAction({
  activity,
  section,
  location,
  isOrganizationEnrollment,
  size = "medium",
  variant = "contained",
  color = "primary",
  trackingSharedProperties = {},
  sx,
}: TrackedButtonProps & { [key: string]: any }) {
  const isSelfPaced = activity.isSelfPaced;

  const isWaitlistable =
    !isSelfPaced && Section.isWaitlistable(activity, section);

  const showNotAcceptingWaitlist =
    !isSelfPaced && Section.isNotAcceptingWaitlistSeats(activity, section);

  const ctaProperties = {
    isOngoing: !!activity.is_ongoing_weekly,
    isSelfPaced,
    isClub: activity.isClub,
    isInProgress: Section.isInProgress(section),
    isWaitlistable,
    sectionProgress: section?.progress,
    showWaitlistedButton: false, // SSR doesn't have users so we won't be able to tell the user they've already been waitlsited
    showNotAcceptingWaitlist,
    canEnroll: activity.canEnroll,
  };
  const cta = useEnrollButtonCTA(ctaProperties);
  const trackingProperties = {
    ...trackingSharedProperties,
    ...ctaProperties,
    activity_uid: activity?.uid,
    section_uid: section?.uid,
    cta,
  };

  if (section && isOrganizationEnrollment) {
    return (
      <SectionOrganizationCallToAction
        activity={activity}
        section={section}
        size={size}
        variant={variant}
        color={color}
        trackingSharedProperties={trackingProperties}
        sx={sx}
      />
    );
  }

  if (section?.isBuyable || activity.isSelfPaced || isWaitlistable) {
    return (
      <SectionEnrollButton
        id={section?.uid || activity.uid}
        activity={activity}
        section={section}
        location={location}
        size={size}
        cta={cta}
        variant={variant}
        color={color}
        trackingSharedProperties={trackingProperties}
        sx={sx}
      />
    );
  }

  return null;
}

function SectionOrganizationCallToAction({
  activity,
  section,
  size,
  variant,
  color,
  trackingSharedProperties = {},
  sx,
}) {
  const Link = useLinkComponent();
  const { t } = useTranslation(
    "ssr-client\\components\\detailPage\\SectionCallToActionButtons"
  );
  const path = sectionCharterSchoolEnrollPath(activity, section);

  return (
    <SectionCallToActionButton
      component={Link}
      to={path}
      size={size}
      variant={variant}
      color={color}
      trackingSharedProperties={trackingSharedProperties}
      sx={sx}
    >
      {t`Admin Enroll`}
    </SectionCallToActionButton>
  );
}

function SectionEnrollButton({
  id,
  activity,
  section,
  location,
  cta,
  size,
  variant,
  color,
  trackingSharedProperties = {},
  sx,
}) {
  const Link = useLinkComponent();
  const path = activity.isSelfPaced
    ? selfPacedEnrollPath(activity)
    : sectionEnrollPath(activity, section);

  return (
    activity &&
    (section || activity.isSelfPaced) && (
      <SectionCallToActionEnrollButton
        as={Link}
        to={path}
        size={size}
        variant={variant}
        color={color}
        id={id}
        location={location}
        trackingName={"Activity Enroll Click"}
        trackingSharedProperties={trackingSharedProperties}
        trackingTouchEventName={"Activity Enroll Click"}
        trackingUniqueId={section?.uid || activity.uid}
        sx={sx}
      >
        {cta}
      </SectionCallToActionEnrollButton>
    )
  );
}

export function SectionCallToActionButton<
  C extends React.ElementType = React.ComponentPropsWithRef<
    typeof TrackedButton
  >["component"]
>({
  size,
  variant = "contained",
  color = "primary",
  ...props
}: TrackedButtonProps<C>) {
  const onClick = event => {
    event.stopPropagation();
  };
  return (
    <TrackedButton
      variant={variant}
      color={color}
      onClick={onClick}
      size={size}
      {...props}
      trackingSharedProperties={{
        is_auto_scheduled_section: false,
        is_fake_section: false,
        ...props.trackingSharedProperties,
      }}
    />
  );
}

export function SectionCallToActionEnrollButton({ as, size, to, ...props }) {
  const { enterLoginFlow } = useLoginFlowContext();
  const enterLoginFlowCallback = React.useCallback(() => {
    return enterLoginFlow({
      authTrigger: AuthTrigger.ENROLL,
      skipLearners: true,
      newUrl: to,
    });
  }, [enterLoginFlow, to]);

  const trackingSharedPropertiesMemo = React.useMemo(() => {
    return {
      is_auto_scheduled_section: false,
      is_fake_section: false,
      ...props.trackingSharedProperties,
    };
  }, [props.trackingSharedProperties]);

  const ButtonMemo = React.useMemo(() => {
    return (
      <TrackedButton
        variant="contained"
        onClick={event => {
          event.stopPropagation();
          // start enroll flow (open sign up modal if not logged in)
          enterLoginFlowCallback();
        }}
        component={as}
        size={size}
        {...props}
        trackingSharedProperties={trackingSharedPropertiesMemo}
        trackViews={true}
      />
    );
  }, [as, enterLoginFlowCallback, props, size, trackingSharedPropertiesMemo]);

  return ButtonMemo;
}
