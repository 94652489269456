import { EsaSessionData } from "@outschool/auth-shared";
import {
  ActivityEnrollableTimes_SsrQuery,
  ActivityEnrollableTimes_SsrQueryVariables,
} from "@outschool/gql-frontend-generated";
import { ActivityEnrollableTimesModal } from "@outschool/ui-components-website";
import gql from "graphql-tag";
import type { ApolloClient } from "@outschool/ui-apollo";

export default async function fetchOneOnOneEnrollableTimes({
  client,
  uid,
  esaSessionData,
}: {
  client: ApolloClient<any>;
  uid: string;
  esaSessionData?: EsaSessionData;
}): Promise<ActivityEnrollableTimes_SsrQuery["activity"]> {
  const { data } = await client.query<
    ActivityEnrollableTimes_SsrQuery,
    ActivityEnrollableTimes_SsrQueryVariables
  >({
    query: gql`
              query ActivityEnrollableTimes_SSR(
                $uid: ID!
                $esaState: String
                $startAfter: DateTime
                $endBy: DateTime
                $filterByDow: [Int]
                $recurringOnly: Boolean
              ) {
                activity(uid: $uid) {
                  ...ActivityEnrollableTimesModal_activity
                }
              }
              ${ActivityEnrollableTimesModal.fragments.activity},
            `,
    variables: {
      uid,
      esaState: esaSessionData?.shipping?.state,
      recurringOnly: false,
    },
    fetchPolicy: "network-only",
  });
  return data?.activity;
}
